import { GetServerSideProps, GetStaticPaths, GetStaticProps } from "next";
import LandingPage, { LandingPageProps } from "../../components/landing/landing-page";
import sendSystemAlert from "../../server/email/system-alert";
import { getProducts } from "../api/plans/get-products";
import { localeFromLangCode } from "../../utils/locale-from-header";
import { localizedPagePaths } from "../../localization/landing";
import Head from "next/head";
import LandingPageKo from "../../components/landing/landing-page-ko";
import { siteConfig } from "configuration/config";

export const getStaticProps: GetStaticProps<LandingPageProps> = async (props) => {
  const locale = localeFromLangCode(props.params?.lang as string);

  try {
    let products = null;

    if (siteConfig.enableStripeBilling) {
      const productsRes = await getProducts();

      if (productsRes.products.length > 0) {
        products = productsRes.products;
      }
    }

    console.log("Get Static Props Params:", props.params);
    return {
      props: {
        ...locale,
        products: products,
        pathLangCode: props.params?.lang as string,
      },
      revalidate: 86400, // 24 hours
    };
  } catch (error: any) {
    console.error(error);
    await sendSystemAlert("Failed to fetch plans on landing page. " + error?.message);
  }

  return {
    props: {
      ...locale,
      products: null,
      pathLangCode: props.params?.lang as string,
    },
    revalidate: 300, // retry in 5 min
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  // Generate paths for each supported locale
  const paths = localizedPagePaths
    .filter((l) => l[0] !== "en")
    .map(([lang]) => ({
      params: { lang },
    }));

  return {
    paths,
    fallback: "blocking", // or 'false' if you don't want to generate pages on demand
  };
};

export default function LocalLandingPage(props: LandingPageProps) {
  return (
    <>
      <Head>
        <title>{props.l["head.title"]}</title>
      </Head>

      {props.pathLangCode === "ko" ? <LandingPageKo {...props} /> : <LandingPage {...props} />}
    </>
  );
}
